<script>
import appConfig from "@/app.config";
import UserService from "@/services/user";

export default {
  page: {
    title: "Trocar Senha",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      loading: false,
      isError: false,
      errorMessage: ''
    }
  },
  methods: {
    changePassword() {
      this.isError = false;

      if (this.password !== this.passwordConfirmation) {
        this.isError = true;
        return this.errorMessage = 'A confirmação da senha não confere';
      }

      this.loading = true;

      UserService.changePassword(this.passwordConfirmation, this.$store.getters.tempToken).then(() => {
        this.loading = false;

        this.$toast.open('Senha alterada com sucesso');
        this.$router.push({ name: 'login' });
      }).catch(() => {
        this.loading = false;
        this.isError = true;
        this.errorMessage = 'Não foi possível alterar a senha. Tente novamente.'
      });
    }
  },
  mounted() {
  }
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="card-body pt-0">
                <div class="bg-default">
                  <div class="row img-login">
                    <img src="@/assets/images/logo-whey.png" alt class="img-fluid" />
                  </div>
                </div>

                <div class="p-2">
                  <div class="alert alert-success text-center mb-4" role="alert">Defina a sua nova senha de acesso</div>
                  <!-- <div class="alert alert-danger text-center mb-4" role="alert">teste</div> -->
                  <b-alert variant="danger" class="mt-3" dismissible>teste</b-alert>
                  <b-alert v-model="isError" variant="danger" class="mt-3" dismissible>{{errorMessage}}</b-alert>

                  <form class="form-horizontal" @submit.prevent="changePassword">
                    <div class="form-group">
                      <label for="password">Nova senha</label>
                      <input
                        type="password"
                        class="form-control"
                        id="password"
                        v-model="password"
                        placeholder="Digite sua senha"
                      />
                    </div>

                    <div class="form-group">
                      <label for="passwordConfirmation">Confirmar senha</label>
                      <input
                        type="password"
                        class="form-control"
                        id="passwordConfirmation"
                        v-model="passwordConfirmation"
                        placeholder="Digite a senha novamente"
                      />
                    </div>

                    <div class="form-group row mb-0">
                      <div class="col-12 text-right">
                        <button class="btn btn-primary w-md" type="submit">Confirmar Senha</button>
                      </div>
                    </div>
                  </form>

                  <div class="row" v-if="loading">
                    <div class="col-12">
                      <div class="text-center my-3">
                      <a href="javascript:void(0);" class="text-primary">
                        <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>